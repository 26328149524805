import React from 'react'
import { PageProps } from 'gatsby'

import { siteMetadata } from '../../gatsby-config'
import Layout from '../components/Layout'
import Meta from '../components/Meta'
import abductionIllustration from '../assets/images/abduction-illustration.svg'

const OrderSubmitted: React.FC<PageProps> = ({ location }: PageProps) => (
  <Layout location={location}>
    <Meta site={siteMetadata} title="404: Seite nicht gefunden" />
    <div
      className="w-100 d-flex pt-5 flex-column justify-content-center align-items-center"
      style={{ marginBottom: '7rem' }}
    >
      <img
        alt="Ghost getting abducted by aliens"
        className="mx-auto w-auto"
        src={abductionIllustration}
        style={{ height: '40vh' }}
      />
      <h2 className="mt-4">Herzlichen Glückwunsch!</h2>
      <h2>Du hast gerade dein Bild gekauft.</h2>
    </div>
  </Layout>
)

export default OrderSubmitted
